<template>

  <div class="home">

    <h1 aria-live="assertive" class="uk-center uk-text-center">404 - page not found</h1>
    <div class="uk-text-center uk-center uk-margin-auto">
      <div class="uk-button uk-margin-small-left uk-margin-small-right uk-button-primary">Action</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',

  components: {

  }
}
</script>
